import { Controller } from "stimulus"
import { post } from "@rails/request.js"

export default class NightwindController extends Controller {

    static targets = ["toggleButton"]

    toggle() {
        document.documentElement.classList.toggle("dark")
        post('/theme/toggle')
    }

}
import { createStore } from 'vuex'

import notifications from './notifications'

const store = createStore({
  modules: {
    notification: notifications
  }
})

export default store

export default {
  state() {
    return {
      notifications: []
    }
  },
  getters: {
    notifications: state => state.notifications
  },
  mutations: {
    pushNotification(state, notification) {
      state.notifications.push(notification)
      console.debug(`Pushed notification: ${notification}`)
    },
    clearNotification(state, notification) {
      const idx = state.notifications.indexOf(notification)
      state.notifications.splice(idx, 1)
      console.debug(`Cleared notification: ${notification}`)
    }
  }
}
import Sortable from "sortablejs";
import { Controller } from "stimulus";

function displayFileSize(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + " " + ["B", "KB", "MB", "GB", "TB"][i];
}

export default class extends Controller {
    static targets = ["uploader", "input", "filesList", "filesListContainer", "imagesList"];

    renderFiles() {
        const filesReducer = (filesHTML, file, idx) => {
            const fileHTML = `
              <tr>
                <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-400 cursor-move flex justify-center">
                  <svg role="img" class="h-6" viewBox="0 0 448 512" fill="currentColor">
                    <path d="M432 288H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm0-112H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z"></path>
                  </svg>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 overflow-ellipsis">
                  ${file.name}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  ${displayFileSize(file.size)}
                </td>
              </tr>
              <input type="number" class="hidden" name="episode[page_indices][]" value="${idx}">
            `;
            return filesHTML.concat(fileHTML);
        };
        this.filesListTarget.innerHTML = this.files.reduce(filesReducer, "");
    }

    renderPreview() {
        const imagesReducer = (imagesHTML, file) => {
            const imageURL = URL.createObjectURL(file);
            const imageHTML = `<img alt="${file.name}" src="${imageURL}">`;
            return imagesHTML.concat(imageHTML);
        };
        const previewHeader = `
          <div>
            <p class="dnd-copy">Drag and drop the above rows and use the preview button below to ensure your images are shown the correct order.</p>
            <div class="btn toggle-preview-btn" data-action="click->upload-list#togglePreview"></div>
          </div>
        `;
        const imagesHTML = this.files.filter(f => f).reduce(imagesReducer, previewHeader);
        this.imagesListTarget.innerHTML = imagesHTML;
    }

    togglePreview() {
        this.imagesListTarget.classList.toggle("hidden");
    }

    hideUploader() {
        this.uploaderTarget.classList.add("hidden");
        this.filesListContainerTarget.classList.remove("hidden");
    }

    onImagesSelected() {
        this.files = Array.from(this.inputTarget.files);
        this.filesIndices = Array.from(this.files.keys());
        this.hideUploader();
        this.renderFiles();
        this.renderPreview();
    }

    connect() {
        this.sortable = Sortable.create(this.filesListTarget, {
            animation: 150,
            ghostClass: "dragging",
            onEnd: this.onEnd.bind(this),
        });
    }

    swap(arr, indexA, indexB) {
        let temp = arr[indexA];
        arr[indexA] = arr[indexB];
        arr[indexB] = temp;
    };

    onEnd(event) {
        this.swap(this.files, event.oldIndex, event.newIndex);
        this.renderPreview();
    }
}
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["preview", "input"]

  previewImage() {
    const uploadedImage = this.inputTarget.files[0]
    if (uploadedImage) {
      this.previewTarget.classList.remove('hidden')
      this.previewTarget.src = URL.createObjectURL(uploadedImage)
    }
  }

}

import { Controller } from "stimulus"
import { enter, leave, toggle } from "../lib/transition"

export default class extends Controller {

  static targets = ["openButton", "menu", "menuClosedIcon", "menuOpenIcon"]

  open() {
    enter(this.menuTarget, {onEnter: this.onEnter})
  }

  close(event) {
    // The click on openButton is already being routed via the click->dropdown#toggle action
    // so the {click,touchend}@window->dropdown#close actions should be ignored for events on openButton
    if (this.openButtonTarget === event.target || this.openButtonTarget.contains(event.target)) return

    leave(this.menuTarget, { onLeave: this.onLeave })
  }

  toggle() {
    toggle(this.menuTarget, { onEnter: this.onEnter, onLeave: this.onLeave })
  }

  get hasIconTargets() {
    return this.hasMenuClosedIconTarget && this.hasMenuOpenIconTarget
  }

  get onEnter() {
    return () => {
      this.openButtonTarget.setAttribute("aria-expanded", "true")
      if (this.hasIconTargets) {
        this.menuClosedIconTarget.classList.add('hidden')
        this.menuOpenIconTarget.classList.remove('hidden')
      }
    }
  }

  get onLeave() {
    return () => {
      this.openButtonTarget.setAttribute("aria-expanded", "false")
      if (this.hasIconTargets) {
        this.menuOpenIconTarget.classList.add('hidden')
        this.menuClosedIconTarget.classList.remove('hidden')
      }
    }
  }
}

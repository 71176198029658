import { Controller } from "stimulus"
import { enter, leave, toggle } from "../lib/transition"

export default class extends Controller {

  static targets = ["button", "dialog"]

  open() {
    enter(this.dialogTarget, {onEnter: this.onEnter})
  }

  close(event) {
    // The click on openButton is already being routed via the click->dropdown#toggle action
    // so the {click,touchend}@window->dropdown#close actions should be ignored for events on openButton
    if (this.buttonTarget === event.target || this.buttonTarget.contains(event.target)) return

    leave(this.dialogTarget, { onLeave: this.onLeave })
  }

  toggle() {
    toggle(this.dialogTarget, { onEnter: this.onEnter, onLeave: this.onLeave })
  }

  get onEnter() {
    return () => {
      this.buttonTarget.setAttribute("aria-expanded", "true")
    }
  }

  get onLeave() {
    return () => {
      this.buttonTarget.setAttribute("aria-expanded", "false")
    }
  }
}

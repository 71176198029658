import { createApp, reactive, defineAsyncComponent } from 'vue'
import VueTippy from 'vue-tippy'
import VueSnip from 'vue-snip'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import store from '../store'

window.addEventListener('load', () => {
  console.log('🧜🏾‍♀️ Afrovana on Vue 🏞 Vite 🏃🏾‍♀️ Rails 🛤')

  console.log('Initializing Sentry 👁🐛')
  Sentry.init({
    dsn: "https://fbe15c752b8947c8838cdc5dee629b2d@o333447.ingest.sentry.io/5949622",
    release: 'afrovana@0.1.0',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1, // Capture 10% of transactions for performance monitoring.
    environment: import.meta.env.MODE,
    enabled: import.meta.env.PROD
  })

  const app = createApp({
    data() {
      return reactive({
        current: {
          authenticated: window.__APP_CONFIG__.authenticated,
          user: window.__APP_CONFIG__.currentUser
        }
      })
    },
    async mounted() {
      console.debug('Vue app mounted')
      document.styleSheets[0].insertRule('[loader] { display: none }')
    }
  })

  app.use(store)
  app.use(VueTippy, { defaultProps: { placement: 'bottom', interactive: true, touch: false } })
  app.use(VueSnip)

  app.component('chapter-editor', defineAsyncComponent(() => import('./ChapterEditor.vue')))
  app.component('chapter-commenter', defineAsyncComponent(() => import('./ChapterCommenter.vue')))
  app.component('episode-commenter', defineAsyncComponent(() => import('./story/EpisodeCommenter.vue')))
  app.component('story-comment-viewer', defineAsyncComponent(() => import('./story/StoryCommentViewer.vue')))
  app.component('text-editor', defineAsyncComponent(() => import('./TextEditor.vue')))
  app.component('subscription-toggler', defineAsyncComponent(() => import('./SubscriptionToggler.vue')))
  app.component('slug-validator', defineAsyncComponent(() => import('./SlugValidator.vue')))
  app.component('form-datepicker', defineAsyncComponent(() => import('./Datepicker.vue')))
  app.component('base-dropdown', defineAsyncComponent(() => import('./Dropdown.vue')))
  app.component('base-popover', defineAsyncComponent(() => import('./Popover.vue')))
  app.component('lazy-img', defineAsyncComponent(() => import('./LazyImage.vue')))

  // Fandoms
  app.component('fandom-feed', defineAsyncComponent(() => import('./fandom/FandomFeed.vue')))
  app.component('fandom-post', defineAsyncComponent(() => import('./fandom/FandomPost.vue')))
  app.component('fandom-post-form', defineAsyncComponent(() => import('./fandom/FandomPostForm.vue')))
  app.component('fandom-picker', defineAsyncComponent(() => import('./fandom/FandomPicker.vue')))
  app.component('fandom-popover', defineAsyncComponent(() => import('./fandom/FandomPopover.vue')))
  app.component('fandom-joiner', defineAsyncComponent(() => import('./fandom/FandomJoiner.vue')))

  // Feed
  app.component('entries-feed', defineAsyncComponent(() => import('./feed/EntriesFeed.vue')))

  app.component('profile-popover', defineAsyncComponent(() => import('./profile/ProfilePopover.vue')))
  app.component('profile-feed', defineAsyncComponent(() => import('./profile/ProfileFeed.vue')))

  app.component('avatar-viewer', defineAsyncComponent(() => import('./AvatarViewer.vue')))
  app.component('post-form', defineAsyncComponent(() => import('./post/PostForm.vue')))
  app.component('post-viewer', defineAsyncComponent(() => import('./post/PostViewer.vue')))
  app.component('comment-form', defineAsyncComponent(() => import('./CommentForm.vue')))
  app.component('base-tooltip', defineAsyncComponent(() => import('./Tooltip.vue')))
  app.component('notification-center', defineAsyncComponent(() => import('./notification/NotificationCenter.vue')))
  app.component('toast-center', defineAsyncComponent(() => import('./notification/ToastCenter.vue')))

  app.component('auth-gate', defineAsyncComponent(() => import('./AuthGate.vue')))
  app.component('theme-switcher', defineAsyncComponent(() => import('./ThemeSwitcher.vue')))

  app.mount('[data-behavior="vue"]')
})

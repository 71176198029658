import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

import "../stylesheets/index.css"
import "../controllers"
import "../components"

window.addEventListener('load', () => {
    if (!window._rails_loaded) Rails.start()
    ActiveStorage.start()
})

import { Controller } from "stimulus"
import { enter, leave } from "../lib/transition"

export default class extends Controller {

  get isPreview() {
    return document.documentElement.hasAttribute("data-turbo-preview")
  }

  connect() {
    if (!this.isPreview) {
      enter(this.element)
    }
  }

  close() {
    leave(this.element)
  }

}
